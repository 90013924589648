// Load background images
jQuery('.load-bg-img').each(function(i, obj) {
  var large_img = jQuery(this).attr('data-bg-large');
  var small_img = jQuery(this).attr('data-bg-small');

  var item = jQuery(this);

  var bgImg = new Image();
  bgImg.onload = function() {
    item.css('background-image', 'url(' + bgImg.src + ')');
    item.addClass('loaded');
  };

  if (jQuery(document).width() > 800) {
    bgImg.src = item.attr('data-bg');
  } else if (jQuery(document).width() > 400) {
    bgImg.src = item.attr('data-bg-small');
  } else {
    bgImg.src = item.attr('data-bg-tiny');
  }
});




// Navigation
jQuery('.dropdown').on('show.bs.dropdown', function() {
  var elem = jQuery(this).find('.dropdown-menu');
  setTimeout(function() {
    elem.addClass('inplace');
  }, 100);
});
jQuery('.dropdown').on('hide.bs.dropdown', function() {
  jQuery(this).find('.dropdown-menu').removeClass('inplace');
})






var load_start_time = new Date();

// Hero slide in
//jQuery(document).ready(function() {
  // If we have a hero with an asset that needs to load and trigger an animation, load it and trigger
  if (jQuery('.page-title img.slide-in').length > 0) {

    var bgImg = new Image();
    bgImg.onload = function() {

      load_end_time = new Date();
      var load_time = load_end_time - load_start_time; //in ms

      // If we're less than 700 ms, add some pause time.
      var pause_time = 10;
      if (load_time < 500) {
        pause_time = 700;
      }


      // HOme page routine.
      if (jQuery('body.home').length > 0) {

        console.log('hiding loader');
        console.log(load_time);
        console.log(pause_time);
        setTimeout(function() {
          jQuery('.loader').addClass('hiding');
        }, pause_time);
        setTimeout(function() {
          jQuery('.loader').addClass('hide');
        }, pause_time + 200);

        setTimeout(function() {
          jQuery('.page-title').addClass('trigger-animations');
        }, pause_time + 200);
      }
      // All other pages
      else {
        setTimeout(function() {
          jQuery('.page-title').addClass('trigger-animations');
        }, 200);
      }


    };
    bgImg.src = jQuery('.page-title img.slide-in').attr('src');


    //setTimeout(function() {
    //  jQuery('.page-title').addClass('trigger-animations');
    //  console.log('trigger animations');
    //}, 300);

  }
//});








// Bio popups
jQuery(document).ready(function() {
  jQuery('.modal-trigger').on('click', function() {
    var id = jQuery(this).attr('id');
    console.log(jQuery('#' + id + '-content .mtitle').html());
    //jQuery('#' + id + ' .mtitle').html();
    jQuery('.modal .modal-title').html(jQuery('#' + id + '-content .mtitle').html());
    jQuery('.modal .modal-body').html(jQuery('#' + id + '-content .mbody').html());

    jQuery('.modal').modal('show');
  });
});

jQuery(document).on('facetwp-refresh', function() {
  jQuery('.facetwp-template').addClass('loading');
  console.log('Refreshing');
});

jQuery(document).on('facetwp-loaded', function() {
  jQuery('.facetwp-template').removeClass('loading');

  // Change label
  jQuery('.facetwp-facet-syracuse_menu .facetwp-radio').each(function() {
    var data_value = jQuery(this).attr('data-value');
    if (data_value == 'syracuse') jQuery(this).html('All');
    //var facet_label = FWP.settings.labels[facet_name];
    //if (jQuery('.facet-label[data-for="' + facet_name + '"]').length < 1) {
    //  jQuery(this).html('View All');
    //}
  });
});

jQuery(document).ready(function() {



  jQuery('.answer.collapse').on('hide.bs.collapse', function() {
    jQuery(this).parent().find('h3').removeClass('showing');
  });

  jQuery('.answer.collapse').on('show.bs.collapse	', function() {
    jQuery(this).parent().find('h3').addClass('showing');
  })

  jQuery('.nav.collapse').on('hide.bs.collapse', function() {
    jQuery('.navbar-container').removeClass('show');
    jQuery('.navbar-toggler').removeClass('show');
    jQuery('html').removeClass('show-nav');
    jQuery('body').removeClass('no-scroll');
    setTimeout(function() {
      jQuery('.navbar-container').removeClass('showing');
      jQuery('.navbar-toggler').removeClass('showing');
      jQuery('html').removeClass('showing-nav');
    }, 100);
  });

  jQuery('.nav.collapse').on('show.bs.collapse	', function() {
    jQuery('.navbar-container').addClass('showing');
    jQuery('.navbar-toggler').addClass('showing');
    jQuery('html').addClass('showing-nav');
    jQuery('body').addClass('no-scroll');


    setTimeout(function() {
      jQuery('.navbar-container').addClass('show');
      jQuery('.navbar-toggler').addClass('show');
      jQuery('html').addClass('show-nav');
    }, 100);
  });

  jQuery('.search-form.collapse').on('show.bs.collapse	', function() {

    setTimeout(function() {
      jQuery('.search-form input.search-field').focus();
    }, 100);
  });

});


// If we have a hero Image
if (jQuery('.page-title').length) {
  var bgImg = new Image();
  bgImg.onload = function() {
    jQuery('.page-title').css('background-image', 'url(' + bgImg.src + ')');
    jQuery('.page-title').addClass('inplace');
  };

  if (jQuery(document).width() > 800) {
    bgImg.src = jQuery('.page-title').attr('data-bg');
  } else if (jQuery(document).width() > 400) {
    bgImg.src = jQuery('.page-title').attr('data-bg-small');
  } else {
    bgImg.src = jQuery('.page-title').attr('data-bg-tiny');
  }
}




/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('.marry-widows h1, .marry-widows h2, .marry-widows h3, .marry-widows p').each(function() {
          var string = $(this).html();
          string = string.replace(/ ([^ ]*)$/, '&nbsp;$1');
          $(this).html(string);
        });


        if (jQuery(document).width() > 850 || 1) {
          var myElement = document.querySelector("header");
          // construct an instance of Headroom, passing the element
          var headroom = new Headroom(myElement, {
            offset: 20,
            tolerance: {
              down: 11,
              up: 3
            }
          });
          // initialise
          headroom.init();
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.




function getScrollTop() {
  if (typeof pageYOffset !== 'undefined') {
    //most browsers except IE before #9
    return pageYOffset;
  } else {
    var B = document.body; //IE 'quirks'
    var D = document.documentElement; //IE with doctype
    D = (D.clientHeight) ? D : B;
    return D.scrollTop;
  }
}



function getHeaderTop(div) {
  var winTop;
  if (!div) {
    winTop = jQuery(window).scrollTop();
  } else {
    winTop = jQuery(div).offset().top;
  }
  var headerTop = winTop;
  if (headerTop > headerHidden) {
    headerTop = headerHidden;
  } else if (headerTop < 0) {
    headerTop = 0;
  }
  return headerTop;
}

// State of the header.
var top_stick = true;
var darker_nav = false;




// Update the navigation based on the width of the viewport.
function updateNav() {
  //
  // Update header background color.
  //
  var from_top = jQuery(window).scrollTop();
  if (from_top > 10 && !darker_nav) {
    jQuery(".header").addClass('scrollnav');
    darker_nav = true;
  } else if (from_top <= 10 && darker_nav) {
    jQuery(".header").removeClass('scrollnav');
    darker_nav = false;
  }
}




jQuery(document).ready(function() {
  jQuery('html').addClass('js');

  //windowResizeTasks();

  // Remove 300ms delay when tapping on touch devices.
  jQuery(function() {
    FastClick.attach(document.body);
  });

  updateNav();
  jQuery(window).scroll(jQuery.throttle(65, updateNav));
  jQuery(window).on("debouncedresize", function(event) {
    // Your event handler code goes here.
    windowResizeTasks();
    updateNav();
  });
});










// Menu filter stuff
jQuery(document).ready(function() {


  jQuery(document).on('facetwp-loaded', function() {


    if ('' == FWP.buildQueryString()) {
      jQuery('.reset-button').addClass('disabled');
      jQuery('.reset-button').attr('disabled', 'disabled');
    } else {
      jQuery('.reset-button').removeClass('disabled');
      jQuery('.reset-button').removeAttr('disabled', 'disabled');
    }


    jQuery('.facetwp-facet-dietary_restrictions .facetwp-checkbox').each(function(index, value) {
      console.log(jQuery(this));
      var icon_class = ''
      if (jQuery(this).attr('data-value') == 'dairy-free') {
        icon_class = 'icon-df';
      }
      else if (jQuery(this).attr('data-value') == 'vegan') {
        icon_class = 'icon-v';
      }
      else if (jQuery(this).attr('data-value') == 'vegetarian') {
        icon_class = 'icon-vg';
      }
      else if (jQuery(this).attr('data-value') == 'gluten-free') {
        icon_class = 'icon-gf';
      }

      jQuery(this).prepend('<span class="icon '+icon_class+'"></span>');
    });


  });


});












function parallaxImg() {

  if (jQuery('.scrolly-background').length > 0) {
    var $el = jQuery('.scrolly-background .inner');
    var $el2 = jQuery('.scrolly-background .image-col');

    // Don't start the scroll unless we're in the range of this element.

    var scroll = jQuery(document).scrollTop();
    $el.css({
        'background-position':'100% '+(-1*.2*scroll + 250)+'px'
    });
    $el2.css({
        'background-position':'0% '+(-1*.15*scroll + 300)+'px'
    });
  }



}


// Parlex
jQuery(document).ready(function() {
  parallaxImg();
  jQuery(window).scroll(jQuery.throttle(20, parallaxImg));
});
