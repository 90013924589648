var instagram = [];
var images_loaded = 0;
var insta_to_get;

var media = [],
  i = 0,
  m = 0,
  endString = "",
  iLength = 1,
  loadString = "",
  loadCount = iLength,
  loadCIndex = 1,
  position = 0,
  totalLength = 0,
  noLoad = false,
  percent_loaded = 0,
  insta_to_show = 8;

function pad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function printInsta(insta) {
  var textString = "",
    description = "";
  var thisDetails = "",
    n_classes = "";
  console.log(insta);

  if (insta.show_word) {
    textString += "<div class='ig size-tall text'>";
    textString += "<div class='inner'>";
    textString += "<h3>" + insta.description + "</h3>";
    textString += "</div>";
    textString += "</div>";
  } else if (insta.is_video) {
    textString = "<div class='ig video " + insta.size + "' id='ig-" + i + "'>";
    textString += "<div class='inner'>";
    textString += "<a href='" + insta.url + "' target='_blank'>";
    textString += "<video loop autoplay muted>";
    textString += "<source src='" + insta.image + "'/>";
    textString += "</video>";
    textString += "</a>";
    textString += "</div>";
    textString += "</div>";
  } else {
    textString = "<div class='ig " + insta.size + "' id='ig-" + i + "'>";
    textString += "<div class='inner'>";
    //textString += "<div class='like'>" + insta.likes + " Likes</div>";
    textString +=
      "<a href='" +
      insta.url +
      "' target='_blank'><img src='" +
      insta.image +
      "' class='imgType_" +
      insta.type +
      "' alt='" +
      description +
      "'></a>";
    textString += "</div>";
    textString += "</div>";
  }
  jQuery("#insta_wrapper").append(textString);
}

function showImages() {
  var insta_found = vars.insta_data.data.length;

  instagram.forEach(function(insta) {
    i++;
    // IF we don't have neough for a full row, break out.
    if (insta_found < 8 && i > 4) {
      return false;
    }

    printInsta(insta);
  });

  jQuery("#insta_wrapper").append('<div class="clearfix"></div>');

  jQuery(".instagram").addClass("loaded");
}

function getInstagram() {
  var i = 0;
  if (vars.insta_data.data) {
    vars.insta_data.data.forEach(function(insta) {
      var isVideo = 0;
      var size = "";

      if (insta.media_type == "VIDEO") {
        isVideo = 1;
      }

      size = "size-tall";

      var newShot = {
        type: "i",
        is_video: isVideo,
        image: insta.media_url,
        url: insta.permalink,
        size: size
      };

      if (images_loaded < insta_to_get) {
        instagram.push(newShot);
      }

      images_loaded++;
    });
    // We're done. Show stuff.
    jQuery("#insta_wrapper").html("");
    showImages();
  }
}

// Instagram feed
if (jQuery("#insta_wrapper").length > 0) {
  if (jQuery(document).width() > 768) {
    insta_to_get = 8;
  } else {
    insta_to_get = 4;
  }
  getInstagram();
}
